<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-0">
              <template v-slot:body>
                <div class="iq-edit-list">
                  <tab-nav :pills="true" class="iq-edit-profile d-flex">
                    <tab-nav-items class="col-md-2 p-0" :active="isNewEmployee" id="tab1" href="#personal-information" :title="$t('employee.personal_info')" />
                    <tab-nav-items class="col-md-2 p-0" :active="!isNewEmployee" id="tab2" href="#services" :title="$t('employee.services')" />
                    <tab-nav-items class="col-md-2 p-0" :active="false" id="tab3" href="#program" :title="$t('employee.program')" />
                    <tab-nav-items class="col-md-2 p-0" :active="false" id="tab4" href="#userrights" :title="$t('employee.user_rights')" />
                    <tab-nav-items class="col-md-2 p-0" :active="false" id="tab5" href="#password" :title="$t('employee.password')" />
                  </tab-nav>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="isNewEmployee" id="personal-information" >
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.personal_info') }}</h4>
                    </template>
                    <template v-slot:body>
                      <b-form @submit.prevent="savePersonalInformation(user)">
                        <b-form-group class="row align-items-center">
                          <b-col md="12">
                            <div class="profile-img-edit">
                              <b-img :src="user.logo" class="height-150" alt="profile-pic"/>
                              <input type="hidden" v-model="user.logo">
                              <div class="p-image">
                                <div class="position-relative">
                                  <i class="ri-pencil-line upload-button">
                                    <input type="file" ref="file" @change="previewImage(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0; left: 0" />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-form-group>
                        <b-row align-v="center">
                          <b-form-group class="col-md-6" label="First Name" label-for="fname">
                            <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                              <b-form-input v-model="user.fname" type="text" :placeholder="$t('employee.first_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-md-6" label="Last Name" label-for="lname">
                            <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                              <b-form-input v-model="user.lname" type="text" :placeholder="$t('employee.last_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>

                          <b-form-group class="col-sm-6" :label="$t('employee.dob')" label-for="dob">
                            <b-form-input type="date" v-model="user.dob" id="dob" value="12-03-2021"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6" :label="$t('employee.gender')" label-class="d-block">
                            <b-form-radio inline v-model="user.gender" value="m">{{ $t('employee.male') }}</b-form-radio>
                            <b-form-radio inline v-model="user.gender" value="f">{{ $t('employee.female') }}</b-form-radio>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="contact_no">{{ $t('employee.contact_no') }}:</label>
                            <b-form-input id="contact_no" type="text" v-model="user.phone"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="email">{{ $t('employee.email') }}:</label>
                            <b-form-input id="email" type="text" v-model="user.email"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="url">{{ $t('employee.url') }}:</label>
                            <b-form-input id="url" type="text" v-model="user.url"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="colopicker">{{ $t('employee.color') }}:</label>
                            <b-form-input id="colopicker" type="color" v-model="user.color"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-12" :label="$t('employee.about')">
                            <b-form-textarea name="about" v-model="user.description" style="line-height: 22px;" rows="5">
                            </b-form-textarea>
                          </b-form-group>
                        </b-row>
                        <b-button type="submit" variant="primary" class="mr-2" >{{ $t('employee.save') }}</b-button>
                      </b-form>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="!isNewEmployee" id="services">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.services') }}</h4>
                      <b-form-checkbox inline style="margin-top: 20px;" v-model="selectAll" @input="toggleSelect">{{ $t('employee.select_all') }}</b-form-checkbox>
                    </template>
                    <template v-slot:body>
                      <b-form @submit.prevent="saveServices()">
                      <b-row style="margin-bottom: 50px; margin-top: 20px">
                        <div v-for="item in services" :key="item.id" class="col-sm-3" >
                          <b-form-checkbox inline :value="item.id" v-model="bigSelects" @input="toggleSmallSelect">{{item.title}}</b-form-checkbox>
                          <div v-for="it in item.list" :key="it.id" style="padding:10px 20px 0px;">
                            <b-form-checkbox inline :value="it.token" v-model="smallSelects">{{it.name}}</b-form-checkbox>
                          </div>
                        </div>
                      </b-row>

                      <b-button type="submit" variant="primary" class="mr-2">{{ $t('employee.submit') }}</b-button>
                      </b-form>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="changepassword">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.change_password') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-form-group>
                          <label for="cpass">{{ $t('employee.current_password') }}:</label>
                          <b-link href="javascripe:void();" class="float-right">{{ $t('employee.forgot_password') }}</b-link>
                          <b-form-input @focusout="changePassword()" type="password" id="cpass" v-model="currentPassword"></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-6" :label="$t('employee.new_password')" label-for="pass">
                          <ValidationProvider name="Password" rules="confirmed:repeat_password" v-slot="{ errors }">
                            <b-form-input v-model="user.password" type="password" placeholder="Password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-6" :label="$t('employee.repeat_password')" label-for="rpass">
                          <ValidationProvider vid="repeat_password" name="Repeat Password" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.repeat_password" type="password" :placeholder="$t('employee.repeat_password')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                        <b-button type="submit" variant="primary" class="mr-2">{{ $t('employee.submit') }}</b-button>
                        <b-button type="reset" variant="none" class="iq-bg-danger">{{ $t('employee.cancel') }}</b-button>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="program">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.program') }}</h4>
                      <b-button type="submit" @click="setCompanyProgram" variant="primary" class="mr-2" style="margin-top:10px">{{ $t('employee.set_program') }}</b-button>
                    </template>
                    <template v-slot:body>
                      <b-form>
                        <b-form @submit.prevent="saveProgram()">
                          <b-row style="margin-bottom: 50px; margin-top: 20px">
                            <div class="row col-sm-12">
                              <div class="col-sm-6">
                                <input type="checkbox" id="monday" value="1" v-model="monday">
                                <label for="monday" style="padding: 0px 10px;">{{ $t('program.monday') }}</label>
                                <div v-if="monday === true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="monday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="monday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-show="monday === true">
                                <input type="checkbox" id="monday_break" value="1" v-model="monday_break" >
                                <label for="monday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="monday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="monday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="monday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <div class="row col-sm-12" >
                              <div class="col-sm-6">
                                <input type="checkbox" id="tuesday" value="2" v-model="tuesday">
                                <label for="tuesday" style="padding: 0px 10px;">{{ $t('program.tuesday') }}</label>
                                <div v-if="tuesday == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="tuesday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="tuesday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-if="tuesday == true">
                                <input type="checkbox" id="tuesday_break" value="2" v-model="tuesday_break" >
                                <label for="tuesday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="tuesday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="tuesday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="tuesday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <div class="row col-sm-12" >
                              <div class="col-sm-6">
                                <input type="checkbox" id="wednesday" value="3" v-model="wednesday">
                                <label for="wednesday" style="padding: 0px 10px;">{{ $t('program.wednesday') }}</label>
                                <div v-if="wednesday == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="wednesday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="wednesday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-if="wednesday == true">
                                <input type="checkbox" id="wednesday_break" value="3" v-model="wednesday_break" >
                                <label for="wednesday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="wednesday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="wednesday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="wednesday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <div class="row col-sm-12" >
                              <div class="col-sm-6">
                                <input type="checkbox" id="thursday" value="4" v-model="thursday">
                                <label for="thursday" style="padding: 0px 10px;">{{ $t('program.thursday') }}</label>
                                <div v-if="thursday == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="thursday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="thursday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-if="thursday == true">
                                <input type="checkbox" id="thursday_break" value="4" v-model="thursday_break" >
                                <label for="thursday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="thursday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="thursday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="thursday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <div class="row col-sm-12" >
                              <div class="col-sm-6">
                                <input type="checkbox" id="friday" value="5" v-model="friday">
                                <label for="friday" style="padding: 0px 10px;">{{ $t('program.friday') }}</label>
                                <div v-if="friday == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="friday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="friday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-if="friday == true">
                                <input type="checkbox" id="friday_break" value="5" v-model="friday_break" >
                                <label for="friday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="friday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="friday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="friday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <div class="row col-sm-12" >
                              <div class="col-sm-6">
                                <input type="checkbox" id="saturday" value="6" v-model="saturday">
                                <label for="saturday" style="padding: 0px 10px;">{{ $t('program.saturday') }}</label>
                                <div v-if="saturday == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="saturday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="saturday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-if="saturday == true">
                                <input type="checkbox" id="saturday_break" value="6" v-model="saturday_break" >
                                <label for="saturday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="saturday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="saturday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="saturday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <div class="row col-sm-12" >
                              <div class="col-sm-6">
                                <input type="checkbox" id="sunday" value="7" v-model="sunday">
                                <label for="sunday" style="padding: 0px 10px;">{{ $t('program.sunday') }}</label>
                                <div v-if="sunday == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.start_time')">
                                      <b-form-input type="time" value="09:00" v-model="sunday_pstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.end_time')">
                                      <b-form-input type="time" value="17:00" v-model="sunday_pend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                              <div class="col-sm-6" v-if="sunday == true">
                                <input type="checkbox" id="sunday_break" value="7" v-model="sunday_break" >
                                <label for="sunday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
                                <div v-if="sunday_break == true" style="margin-bottom: 20px">
                                  <b-row>
                                    <b-form-group class="col-md-6" :label="$t('program.break_start')">
                                      <b-form-input type="time" value="12:00" v-model="sunday_bstart"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" :label="$t('program.break_end')">
                                      <b-form-input type="time" value="13:00" v-model="sunday_bend"></b-form-input>
                                    </b-form-group>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                          </b-row>
                          <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                        </b-form>
                      </b-form>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="userrights">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.user_rights') }}</h4>
                    </template>
                    <template v-slot:body>
                      <div class="form-group row align-items-center d-none" >
                        <label class="col-md-3">{{ $t('employee.can_edit') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_personal" v-model="edit_personal">
                            <label class="custom-control-label" for="edit_personal">{{ $t('employee.his_personal_informations') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_his_services" v-model="edit_his_services">
                            <label class="custom-control-label" for="edit_his_services">{{ $t('employee.his_services') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_his_program" v-model="edit_his_program">
                            <label class="custom-control-label" for="edit_his_program">{{ $t('employee.his_program') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_his_rights" v-model="edit_his_rights">
                            <label class="custom-control-label" for="edit_his_rights">{{ $t('employee.his_rights') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="edit_his_password" v-model="edit_his_password">
                          <label class="custom-control-label" for="edit_his_password">{{ $t('employee.his_password') }}</label>
                        </div>
                        </div>
                      </div>

                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.display') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="display_website" v-model="display_website">
                            <label class="custom-control-label" for="display_website">{{ $t('employee.display_on_website') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="display_calendar" v-model="display_calendar">
                            <label class="custom-control-label" for="display_calendar">{{ $t('employee.display_on_calendar') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('sidebar.dashboard') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_dashboard" v-model="access_dashboard">
                            <label class="custom-control-label" for="access_dashboard">{{ $t('customer_.access_dashboard') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.calendar') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_calendar" v-model="access_calendar">
                            <label class="custom-control-label" for="access_calendar">{{ $t('employee.access_calendar') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="see_phone" v-model="see_phone">
                            <label class="custom-control-label" for="see_phone">{{ $t('employee.see_phone_number') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="see_others" v-model="see_others">
                            <label class="custom-control-label" for="see_others">{{ $t('employee.see_other_employees') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_bookings" v-model="edit_bookings">
                            <label class="custom-control-label" for="edit_bookings">{{ $t('employee.edit_bookings') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="add_past" v-model="add_past">
                            <label class="custom-control-label" for="add_past">{{ $t('feature.add_past') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.services') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_services" v-model="access_services">
                            <label class="custom-control-label" for="access_services">{{ $t('employee.access_services') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.employees') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_employees" v-model="access_employees">
                            <label class="custom-control-label" for="access_employees">{{ $t('employee.access_employees') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.clients') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_clients" v-model="access_clients">
                            <label class="custom-control-label" for="access_clients">{{ $t('employee.access_clients') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.message') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="receive_website_messages" v-model="receive_website_messages">
                            <label class="custom-control-label" for="receive_website_messages">{{ $t('employee.website_messages') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="receive_sms_messages" v-model="receive_sms_messages">
                            <label class="custom-control-label" for="receive_sms_messages">{{ $t('employee.sms_messages') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="receive_email_messages" v-model="receive_email_messages">
                            <label class="custom-control-label" for="receive_email_messages">{{ $t('employee.email_messages') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="send_messages" v-model="send_messages">
                            <label class="custom-control-label" for="send_messages">{{ $t('employee.send_messages') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.website') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_website" v-model="edit_website">
                            <label class="custom-control-label" for="edit_website">{{ $t('employee.edit_website') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                      <label class="col-md-3">{{ $t('employee.payment') }}</label>
                      <div class="col-md-9">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="edit_payment" v-model="edit_payment">
                          <label class="custom-control-label" for="edit_payment">{{ $t('employee.edit_payment') }}</label>
                        </div>
                      </div>
                    </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.company') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="edit_company" v-model="edit_company">
                            <label class="custom-control-label" for="edit_company">{{ $t('employee.edit_company') }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-md-3">{{ $t('employee.reports') }}</label>
                        <div class="col-md-9">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_reports" v-model="access_reports">
                            <label class="custom-control-label" for="access_reports">{{ $t('employee.access_reports') }}</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="access_reports_see_others" v-model="access_reports_see_others">
                            <label class="custom-control-label" for="access_reports_see_others">{{ $t('customer_.access_reports_see_others') }}</label>
                          </div>
                        </div>
                      </div>

                      <b-button type="submit" @click="saveNotifications" variant="primary" class="mr-2">{{ $t('employee.submit') }}</b-button>
                    </template>
                  </iq-card>

                </tab-content-item>
                <tab-content-item :active="false" id="password">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.change_password') }}</h4>
                    </template>
                    <template v-slot:body>
                      <b-form-group class="col-md-6" :label="$t('employee.new_password')" label-for="pass">
                        <b-form-input v-model="user.password" type="password" :placeholder="$t('employee.new_password')"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-6" :label="$t('employee.repeat_password')" label-for="rpass">
                        <b-form-input v-model="user.repeat_password" type="password" :placeholder="$t('employee.repeat_password')"></b-form-input>
                      </b-form-group>
                      <b-button type="button" @click="saveUserPassword(user)" variant="primary" class="mr-2">{{ $t('employee.submit') }}</b-button>
                    </template>
                  </iq-card>
                </tab-content-item>
                <div class="isLoading" v-if="isLoading"><div class="loader"></div></div>
              </tab-content>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'ProfileEdit',
  mounted () {
    core.index()
    this.getServices()
    this.getEmployeedata()
  },
  created () {
    this.checkNext()
  },
  data () {
    return {
      isNewEmployee: true,
      isLoading: false,
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      isNew: this.$route.params.id === null,
      user: {
        fname: '',
        lname: '',
        name: '',
        username: '',
        email: '',
        password: '',
        repeat_password: '',
        address1: '',
        address2: '',
        company_name: '',
        logo: require('../../assets/images/user/employee_placeholder.png'),
        phone: '',
        role: '',
        gender: '',
        dob: '',
        url: '',
        services: []
      },
      currentPassword: '',
      selectAll: false,
      services: [],
      bigSelects: [],
      smallSelects: [],
      currentProgram: {},
      monday: '',
      monday_break: '',
      monday_bstart: '12:00',
      monday_pstart: '09:00',
      monday_bend: '13:00',
      monday_pend: '17:00',
      tuesday: '',
      tuesday_break: '',
      tuesday_bstart: '12:00',
      tuesday_pstart: '09:00',
      tuesday_bend: '13:00',
      tuesday_pend: '17:00',
      wednesday: '',
      wednesday_break: '',
      wednesday_bstart: '12:00',
      wednesday_pstart: '09:00',
      wednesday_bend: '13:00',
      wednesday_pend: '17:00',
      thursday: '',
      thursday_break: '',
      thursday_bstart: '12:00',
      thursday_pstart: '09:00',
      thursday_bend: '13:00',
      thursday_pend: '17:00',
      friday: '',
      friday_break: '',
      friday_bstart: '12:00',
      friday_pstart: '09:00',
      friday_bend: '13:00',
      friday_pend: '17:00',
      saturday: '',
      saturday_break: '',
      saturday_bstart: '12:00',
      saturday_pstart: '09:00',
      saturday_bend: '13:00',
      saturday_pend: '17:00',
      sunday: '',
      sunday_break: '',
      sunday_bstart: '12:00',
      sunday_pstart: '09:00',
      sunday_bend: '13:00',
      sunday_pend: '17:00',
      // userrights
      edit_personal: false,
      edit_his_services: false,
      edit_his_program: false,
      edit_his_rights: false,
      edit_his_password: false,
      access_dashboard: false,
      display_website: false,
      display_calendar: false,
      access_calendar: false,
      see_phone: false,
      see_others: false,
      edit_bookings: false,
      add_past: false,
      access_services: false,
      access_employees: false,
      access_clients: false,
      receive_website_messages: false,
      receive_sms_messages: false,
      receive_email_messages: false,
      send_messages: false,
      edit_website: false,
      edit_payment: false,
      edit_company: false,
      access_reports: false,
      access_reports_see_others: false
    }
  },
  methods: {
    checkNext () {
      if (window.location.hash === '#success') {
        this.isNewEmployee = false
      }
    },
    setCompanyProgram () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_GET_COMPANY_PROGRAM)
        .then((response) => {
          if (response.data.status === 'success') {
            const currentProgram = JSON.parse(response.data.program.program)
            this.monday = currentProgram.monday
            this.monday_bstart = currentProgram.monday_bstart
            this.monday_pstart = currentProgram.monday_pstart
            this.monday_break = currentProgram.monday_break
            this.monday_bend = currentProgram.monday_bend
            this.monday_pend = currentProgram.monday_pend
            this.tuesday = currentProgram.tuesday
            this.tuesday_bstart = currentProgram.tuesday_bstart
            this.tuesday_pstart = currentProgram.tuesday_pstart
            this.tuesday_break = currentProgram.tuesday_break
            this.tuesday_bend = currentProgram.tuesday_bend
            this.tuesday_pend = currentProgram.tuesday_pend
            this.wednesday = currentProgram.wednesday
            this.wednesday_bstart = currentProgram.wednesday_bstart
            this.wednesday_pstart = currentProgram.wednesday_pstart
            this.wednesday_break = currentProgram.wednesday_break
            this.wednesday_bend = currentProgram.wednesday_bend
            this.wednesday_pend = currentProgram.wednesday_pend
            this.thursday = currentProgram.thursday
            this.thursday_bstart = currentProgram.thursday_bstart
            this.thursday_pstart = currentProgram.thursday_pstart
            this.thursday_break = currentProgram.thursday_break
            this.thursday_bend = currentProgram.thursday_bend
            this.thursday_pend = currentProgram.thursday_pend
            this.friday = currentProgram.friday
            this.friday_bstart = currentProgram.friday_bstart
            this.friday_pstart = currentProgram.friday_pstart
            this.friday_break = currentProgram.friday_break
            this.friday_bend = currentProgram.friday_bend
            this.friday_pend = currentProgram.friday_pend
            this.saturday = currentProgram.saturday
            this.saturday_bstart = currentProgram.saturday_bstart
            this.saturday_pstart = currentProgram.saturday_pstart
            this.saturday_break = currentProgram.saturday_break
            this.saturday_bend = currentProgram.saturday_bend
            this.saturday_pend = currentProgram.saturday_pend
            this.sunday = currentProgram.sunday
            this.sunday_bstart = currentProgram.sunday_bstart
            this.sunday_pstart = currentProgram.sunday_pstart
            this.sunday_break = currentProgram.sunday_break
            this.sunday_bend = currentProgram.sunday_bend
            this.sunday_pend = currentProgram.sunday_pend
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getServices () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SERVICES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.services = response.data.services
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getEmployeedata () {
      const dataPost = {
        company: this.companyToken,
        employee: this.$route.params.id || null
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_EMPLOYEE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.user = response.data.employees
            if (this.user.logo === '') {
              this.user.logo = require('../../assets/images/user/employee_placeholder.png')
            }
            if (response.data.employees.services !== '') {
              const currentServices = JSON.parse(response.data.employees.services)
              if (currentServices) {
                for (let i = 0; i < currentServices.length; i++) {
                  this.smallSelects.push(currentServices[i])
                }
              }
            }
            if (response.data.employees.program !== '') {
              const currentProgram = JSON.parse(response.data.employees.program)
              this.monday = currentProgram.monday
              this.monday_bstart = currentProgram.monday_bstart
              this.monday_pstart = currentProgram.monday_pstart
              this.monday_break = currentProgram.monday_break
              this.monday_bend = currentProgram.monday_bend
              this.monday_pend = currentProgram.monday_pend
              this.tuesday = currentProgram.tuesday
              this.tuesday_bstart = currentProgram.tuesday_bstart
              this.tuesday_pstart = currentProgram.tuesday_pstart
              this.tuesday_break = currentProgram.tuesday_break
              this.tuesday_bend = currentProgram.tuesday_bend
              this.tuesday_pend = currentProgram.tuesday_pend
              this.wednesday = currentProgram.wednesday
              this.wednesday_bstart = currentProgram.wednesday_bstart
              this.wednesday_pstart = currentProgram.wednesday_pstart
              this.wednesday_break = currentProgram.wednesday_break
              this.wednesday_bend = currentProgram.wednesday_bend
              this.wednesday_pend = currentProgram.wednesday_pend
              this.thursday = currentProgram.thursday
              this.thursday_bstart = currentProgram.thursday_bstart
              this.thursday_pstart = currentProgram.thursday_pstart
              this.thursday_break = currentProgram.thursday_break
              this.thursday_bend = currentProgram.thursday_bend
              this.thursday_pend = currentProgram.thursday_pend
              this.friday = currentProgram.friday
              this.friday_bstart = currentProgram.friday_bstart
              this.friday_pstart = currentProgram.friday_pstart
              this.friday_break = currentProgram.friday_break
              this.friday_bend = currentProgram.friday_bend
              this.friday_pend = currentProgram.friday_pend
              this.saturday = currentProgram.saturday
              this.saturday_bstart = currentProgram.saturday_bstart
              this.saturday_pstart = currentProgram.saturday_pstart
              this.saturday_break = currentProgram.saturday_break
              this.saturday_bend = currentProgram.saturday_bend
              this.saturday_pend = currentProgram.saturday_pend
              this.sunday = currentProgram.sunday
              this.sunday_bstart = currentProgram.sunday_bstart
              this.sunday_pstart = currentProgram.sunday_pstart
              this.sunday_break = currentProgram.sunday_break
              this.sunday_bend = currentProgram.sunday_bend
              this.sunday_pend = currentProgram.sunday_pend
            }
            if (response.data.employees.notifications !== '') {
              const currentNotifications = JSON.parse(response.data.employees.notifications)
              this.edit_personal = currentNotifications.edit_personal
              this.edit_his_services = currentNotifications.edit_his_services
              this.edit_his_program = currentNotifications.edit_his_program
              this.edit_his_rights = currentNotifications.edit_his_rights
              this.edit_his_password = currentNotifications.edit_his_password
              this.display_website = currentNotifications.display_website
              this.display_calendar = currentNotifications.display_calendar
              this.access_calendar = currentNotifications.access_calendar
              this.see_phone = currentNotifications.see_phone
              this.see_others = currentNotifications.see_others
              this.edit_bookings = currentNotifications.edit_bookings
              this.add_past = currentNotifications.add_past
              this.access_services = currentNotifications.access_services
              this.access_employees = currentNotifications.access_employees
              this.access_clients = currentNotifications.access_clients
              this.receive_website_messages = currentNotifications.receive_website_messages
              this.receive_sms_messages = currentNotifications.receive_sms_messages
              this.receive_email_messages = currentNotifications.receive_email_messages
              this.send_messages = currentNotifications.send_messages
              this.edit_website = currentNotifications.edit_website
              this.edit_payment = currentNotifications.edit_payment
              this.edit_company = currentNotifications.edit_company
              this.access_reports = currentNotifications.access_reports
              this.access_reports_see_others = currentNotifications.access_reports_see_others
              this.access_dashboard = currentNotifications.access_dashboard
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    savePersonalInformation (item) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('company', this.companyToken)
      formData.append('saveType', 'personalInformation')
      if (this.$refs.file.files[0]) {
        formData.append('file', this.$refs.file.files[0])
      }
      formData.append('employee', this.$route.params.id || null)
      formData.append('fname', item.fname)
      formData.append('lname', item.lname)
      formData.append('dob', item.dob)
      formData.append('gender', item.gender)
      formData.append('phone', item.phone)
      formData.append('email', item.email)
      formData.append('url', item.url)
      formData.append('color', item.color)
      formData.append('description', item.description)
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_EMPLOYEE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', 'Account details have been updated!')
            if (this.$route.params.id === response.data.token) {
              this.$emit('set_userdetails', response.data.name, response.data.logo)
            }
            this.isLoading = false
          } else if (response.data.status === 'upgrade') {
            this.isLoading = false
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          }
        })
        .catch((error) => {
          this.isLoading = false
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveServices () {
      this.isLoading = true
      const formData = {
        company: this.companyToken,
        employee: this.$route.params.id || null,
        services: this.smallSelects,
        saveType: 'services'
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_EMPLOYEE, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isLoading = false
            core.showSnackbar('success', 'Account details have been updated!')
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveProgram () {
      this.isLoading = true
      const program = {
        monday: this.monday,
        monday_bstart: this.monday_bstart,
        monday_pstart: this.monday_pstart,
        monday_break: this.monday_break,
        monday_bend: this.monday_bend,
        monday_pend: this.monday_pend,
        tuesday: this.tuesday,
        tuesday_bstart: this.tuesday_bstart,
        tuesday_pstart: this.tuesday_pstart,
        tuesday_break: this.tuesday_break,
        tuesday_bend: this.tuesday_bend,
        tuesday_pend: this.tuesday_pend,
        wednesday: this.wednesday,
        wednesday_bstart: this.wednesday_bstart,
        wednesday_pstart: this.wednesday_pstart,
        wednesday_break: this.wednesday_break,
        wednesday_bend: this.wednesday_bend,
        wednesday_pend: this.wednesday_pend,
        thursday: this.thursday,
        thursday_bstart: this.thursday_bstart,
        thursday_pstart: this.thursday_pstart,
        thursday_break: this.thursday_break,
        thursday_bend: this.thursday_bend,
        thursday_pend: this.thursday_pend,
        friday: this.friday,
        friday_bstart: this.friday_bstart,
        friday_pstart: this.friday_pstart,
        friday_break: this.friday_break,
        friday_bend: this.friday_bend,
        friday_pend: this.friday_pend,
        saturday: this.saturday,
        saturday_bstart: this.saturday_bstart,
        saturday_pstart: this.saturday_pstart,
        saturday_break: this.saturday_break,
        saturday_bend: this.saturday_bend,
        saturday_pend: this.saturday_pend,
        sunday: this.sunday,
        sunday_bstart: this.sunday_bstart,
        sunday_pstart: this.sunday_pstart,
        sunday_break: this.sunday_break,
        sunday_bend: this.sunday_bend,
        sunday_pend: this.sunday_pend
      }
      const formData = {
        company: this.companyToken,
        employee: this.$route.params.id || null,
        program: program,
        saveType: 'program'
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_EMPLOYEE, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isLoading = false
            core.showSnackbar('success', 'Account details have been updated!')
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveNotifications () {
      this.isLoading = true
      const notifications = {
        edit_personal: this.edit_personal,
        edit_his_services: this.edit_his_services,
        edit_his_program: this.edit_his_program,
        edit_his_rights: this.edit_his_rights,
        edit_his_password: this.edit_his_password,
        display_website: this.display_website,
        display_calendar: this.display_calendar,
        access_calendar: this.access_calendar,
        see_phone: this.see_phone,
        see_others: this.see_others,
        edit_bookings: this.edit_bookings,
        add_past: this.add_past,
        access_services: this.access_services,
        access_employees: this.access_employees,
        access_clients: this.access_clients,
        receive_website_messages: this.receive_website_messages,
        receive_sms_messages: this.receive_sms_messages,
        receive_email_messages: this.receive_email_messages,
        send_messages: this.send_messages,
        edit_website: this.edit_website,
        edit_payment: this.edit_payment,
        edit_company: this.edit_company,
        access_reports: this.access_reports,
        access_reports_see_others: this.access_reports_see_others,
        access_dashboard: this.access_dashboard
      }
      const formData = {
        company: this.companyToken,
        employee: this.$route.params.id || null,
        notifications: notifications,
        saveType: 'notifications'
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_EMPLOYEE, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isLoading = false
            core.showSnackbar('success', 'User successfully saved')
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
      this.savePersonalInformation(task)
    },
    saveUserPassword (item) {
      this.isLoading = true
      if (item.password === '') {
        core.showSnackbar('error', 'Please add a new password!')
      } else if (item.password !== item.repeat_password) {
        core.showSnackbar('error', 'Passwords do not match!')
      } else {
        const formData = {
          id: item.token,
          password: item.password
        }
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_SAVE_ACCOUNT_PASSWORD, formData)
          .then((response) => {
            if (response.data.status === 'success') {
              this.isLoading = false
              core.showSnackbar('success', 'Account details have been updated!')
            } else if (response.data.status === 'fail') {
              this.isLoading = false
              core.showSnackbar('error', response.data.message)
            }
          })
          .catch((error) => {
            console.error(error.response)
            this.isLoading = false
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    toggleSelect () {
      this.bigSelects = []
      if (this.selectAll) {
        for (let i = 0; i < this.services.length; i++) {
          this.bigSelects.push(this.services[i].id.toString())
        }
      }
    },
    toggleSmallSelect () {
      this.smallSelects = []
      if (this.bigSelects) {
        for (let i = 0; i < this.services.length; i++) {
          const doesIncludes = this.bigSelects.includes(this.services[i].id)
          if (doesIncludes || this.services[i].id === parseInt(this.bigSelects[i])) {
            for (let j = 0; j < this.services[i].list.length; j++) {
              this.smallSelects.push(this.services[i].list[j].token)
            }
          }
        }
      }
    }
  }
}
</script>

<style>
.isLoading{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background: #00000078;
  width: 100%;
  height: 100%;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #19bdb4;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
